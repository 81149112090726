<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-id"
                label-cols-lg="2"
                label="ID"
                label-for="field-id"
              >
                <b-form-input
                  id="field-id-input"
                  disabled
                  v-model="dto.id"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-created"
                label-cols-lg="2"
                :label="$t('COMMON.CREATED')"
                label-for="field-created"
              >
                <b-form-input
                  id="field-created-input"
                  disabled
                  v-model="dto.created"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-user"
                label-cols-lg="2"
                :label="$t('COMMON.USER')"
                label-for="field-user"
              >
                <b-form-input
                  id="field-user-input"
                  disabled
                  v-model="dto.userName"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-orderState"
                label-cols-lg="2"
                :label="$t('ORDERS.ORDER_STATE')"
                label-for="field-orderState-input"
              >
                <b-form-input
                  id="field-orderState-input"
                  disabled
                  v-model="dto.orderStateName"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-products"
                label-cols-lg="2"
                :label="$t('MENU.PRODUCTS')"
                label-for="field-products-input"
              >
                <b-button
                  id="popover-button-variant"
                  tabindex="0"
                  :disabled="_.size(this.dto.products) === 0"
                  :class="{
                    'count--products__active': _.size(this.dto.products) > 0,
                  }"
                  class="count--products"
                >
                  {{ $t("MENU.PRODUCTS") }}:
                  <span>{{ _.size(this.dto.products) }}</span>
                </b-button>
                <b-popover
                  v-if="_.size(this.dto.products) > 0"
                  target="popover-button-variant"
                  placement="bottom"
                  triggers="focus"
                >
                  <b-list-group>
                    <b-list-group-item
                      v-for="(product, index) in this.dto.products"
                      :key="index"
                      class="d-flex justify-content-between align-items-center"
                      style="width: 200px"
                    >
                      {{ product.nameLocalized }}
                    </b-list-group-item>
                  </b-list-group>
                </b-popover>
              </b-form-group>

              <b-form-group
                id="field-discount"
                label-cols-lg="2"
                :label="$t('ORDERS.DISCOUNT')"
                label-for="field-discount"
              >
                <b-form-input
                  id="field-discount-input"
                  disabled
                  v-model="dto.discountName"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-paymentSystem"
                label-cols-lg="2"
                :label="$t('ORDERS.PAYMENT_SYSTEM')"
                label-for="field-paymentSystem"
              >
                <b-form-input
                  id="field-paymentSystem-input"
                  disabled
                  v-model="dto.paymentSystemName"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-amount"
                label-cols-lg="2"
                :label="$t('TRANSACTIONS.AMOUNT')"
                label-for="field-amount"
              >
                <b-form-input
                  id="field-amount-input"
                  disabled
                  v-model="dto.amount"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-discountAmount"
                label-cols-lg="2"
                :label="$t('ORDERS.DISCOUNT_AMOUNT')"
                label-for="field-discountAmount"
              >
                <b-form-input
                  id="field-discountAmount-input"
                  disabled
                  v-model="dto.discountAmount"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-totalAmount"
                label-cols-lg="2"
                :label="$t('PAYMENTS.TOTAL_AMOUNT')"
                label-for="field-totalAmount"
              >
                <b-form-input
                  id="field-totalAmount-input"
                  readonly
                  disabled
                  v-model="dto.totalAmount"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-paid"
                label-cols-lg="2"
                :label="$t('ORDERS.PAID')"
                label-for="field-paid"
              >
                <b-form-input
                  id="field-paid-input"
                  disabled
                  v-model="dto.paid"
                ></b-form-input>
              </b-form-group>
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button v-on:click="onBackClicked">{{
            $t("AUTH.GENERAL.BACK_BUTTON")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";

export default {
  mixins: [SuggestionProcessorMixin],
  name: "order",
  components: {},
  data() {
    return {
      dto: {
        id: null /*надо*/,
        created: null /*надо*/,
        changed: null,
        userId: null /*надо*/,
        userName: null /*надо*/,
        orderStateId: null /*надо*/,
        orderStateName: null /*надо*/,
        discountId: null /*надо*/,
        discountName: null /*надо*/,
        paymentSystemId: null /*надо*/,
        paymentSystemName: null /*надо*/,
        amount: null /*надо*/,
        discountAmount: null /*надо*/,
        totalAmount: null /*надо*/,
        paid: null /*надо*/,
        externalIdentifier: null,
        targetCurrency: null,
        ownershipType: null,
        parentOrder: null,
        periodicity: null,
        products: [],
      },

      getOrderStates: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.market.model.OrderState",
        requestType: "GET",
        requestParams: {},
      },

      filterProducts: "",
      productName: "",
      filterUser: "",

      selectedUserId: null,

      discounts: [],
      products: [],
      randomArray: [],
      currencyTypes: [],
      discountTypes: [],

      showOverlay: true,
      stateMap: {},
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.ORDERS"), route: "/orders" },
      { title: this.$t("ORDERS.VIEW_ORDER") },
    ]);
  },

  created: async function () {
    await this.loadStates();
    if (this.$route.params != null && this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/order/" + this.$route.params.id,
          requestType: "GET",
          requestParams: { enrich: true, withProducts: true },
        })
        .then((response) => {
          this.dto = response;
          this.dto.orderStateName = this.stateFormatter(this.dto.orderStateId);
        })
        .catch(this.onError);
    }
    this.uploadUserNameById("userSuggestionProcessor", this.dto.userId, null);
    this.uploadDiscountNameById(
      "discountSuggestionProcessor",
      this.dto.discountId,
      null
    );
    this.showOverlay = false;
  },

  methods: {
    loadStates: function () {
      this.$store
        .dispatch(API_REQUEST, this.getOrderStates)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.stateMap[response[i].id] = response[i];
          }
        })
        .catch(this.onError);
    },
    uploadUserNameById: function (suggestionProcessorBeanName, query, flag) {
      this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/suggestion/" + suggestionProcessorBeanName,
          requestType: "GET",
          requestParams: { query: query, flags: flag },
        })
        .then((response) => {
          this.dto.userName = response[0].name;
        })
        .catch(this.onError);
    },

    uploadDiscountNameById: function (
      suggestionProcessorBeanName,
      query,
      flag
    ) {
      this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/suggestion/" + suggestionProcessorBeanName,
          requestType: "GET",
          requestParams: { query: query, flags: flag },
        })
        .then((response) => {
          this.dto.discountName = response[0].name;
        })
        .catch(this.onError);
    },

    onItemSelected: function (value) {
      this.selectedUserId = value.id;
    },

    onProductSelected(data) {
      //@ts-ignore
      this.selectedProducts.push(data);

      this.$nextTick(() => {
        //@ts-ignore
        this.clearSuggestedVariants();
        this.filterProducts = "";
        //@ts-ignore
        this.$refs["productsTypeahead"].$data.inputValue = "";
        // @ts-ignore
        this.$refs["productsTypeahead"].$refs["input"].focus();
      });
    },

    onBackClicked: function () {
      this.$router.push({ name: "orders" });
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    stateFormatter: function (value) {
      if (value && value in this.stateMap)
        return this.stateMap[value].nameLocalized;

      return "";
    },
  },
  filters: {},
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
